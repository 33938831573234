<script setup>
import store from "@/store";
import { computed, onMounted, ref, reactive } from "vue";
import LoadUser from "@/components/LoadUser.vue";
import Navigation from "@/components/Navigation.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import router from "@/router";
import { DateTime } from "luxon";
import InputCopyClipboard from "@/components/InputCopyClipboard.vue";
import Overlay from "../components/Overlay.vue";
import Modal from "../components/Modal.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Fixed from "@/components/Fixed.vue";

const user = computed(() => {
  return store.state.auth.user;
});

// watch(user, (newVal, oldVal) => {
//   if (newVal === null) {
//     router.push({ name: "Home" });
//   }
// });

const formatDate = (date) => {
  return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) : null;
};

const formatNumber = (num) => {
  return num?.toLocaleString("en-US") ?? null;
};

const pluralize = (word, quantity) => {
  if (quantity === 1) {
    switch (word) {
      case "chip_bonus":
        return "bonus chip";
      case "chip":
        return "chip";
      case "point":
        return "point";
      case "medallion":
        return "medallion";
      default:
        return "credits";
    }
  }
  switch (word) {
    case "point":
      return "points";
    case "chip":
      return "chips";
    case "chip_bonus":
      return "bonus chips";
    case "medallion":
      return "medallions";
    case "credit":
      return "credits";
  }

  return word;
};

const isLoggedIn = computed(() => {
  return store.getters["auth/isLoggedIn"];
});

const loading = ref(false);
const login = async () => {
  router.push({ name: "Login", query: { from: encodeURI("/arcade") } });
  // loading.value = true;
  // await store.dispatch("auth/login");
  // loading.value = false;
};

const redeemChips = async () => {
  loading.value = true;
  await store.dispatch("arcade/redeemChips");
  await store.dispatch("auth/getUser");
  loading.value = false;
};

const redeemPoints = async () => {
  loading.value = true;
  await store.dispatch("arcade/redeemPoints");
  await store.dispatch("auth/getUser");
  loading.value = false;
};

const redeemMedallions = async () => {
  loading.value = true;
  await store.dispatch("arcade/redeemMedallions");
  await store.dispatch("auth/getUser");
  loading.value = false;
};

onMounted(() => {
  store.dispatch("auth/getUser");
});

const showDetails = reactive({
  poker: false,
  trivia: false,
});

const setShowDetail = (prop, val) => {
  showDetails[prop] = val;
};

const showRedemptionsModal = ref(false);
</script>

<template>
  <LoadUser :redirect="false">
    <div
      class="h-full bg-cover flex items-center justify-center bg-center relative flex-col"
      :style="{
        'background-image': `url('${require('@/assets/backgrounds/arcade-v3-blurred.jpg')}')`,
      }"
    >
      <NavigationNew mode="light" />

      <Breadcrumbs :links="[{ routeName: 'Home', label: 'Home' }, { label: 'Arcade' }]" />

      <div class="flex-grow flex items-center justify-center w-full relative z-20">
        <template v-if="!isLoggedIn">
          <div
            class="p-8 m-4 w-full max-w-md bg-white rounded-xl box-shadow-std text-center flex items-center justify-center flex-col"
          >
            <div class="mb-6 font-bold">You must be logged in to play in the arcade.</div>
            <div>
              <button @click="login" class="btn bg-ch1blue text-white">Login</button>
            </div>
          </div>
        </template>
        <div class="container mb-24" v-else>
          <div class="mb-16">
            <div class="p-8 rounded-xl bg-white box-shadow-std mb-8">
              <div class="mb-4 pb-4 border-b border-gray-500">
                <h1 class="font-maindisplay font-bold text-lg">Trivia</h1>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <router-link :to="{ name: 'ArcadeTrivia' }">
                    <div
                      class="h-48 bg-cover flex items-center justify-center bg-center rounded-lg"
                      :style="{
                        'background-image': `url('${require('@/assets/backgrounds/hq-v1.jpg')}')`,
                      }"
                    >
                      <div class="btn btn-ch1lightblue"><strong>Play Daily Trivia</strong></div>
                    </div>
                    <div></div>
                  </router-link>
                </div>
                <div>
                  <div class="flex flex-col" v-if="user?.points">
                    <div class="text-lg mb-0 text-gray-400">Total Winnings</div>
                    <div class="text-base mb-4">
                      {{ formatNumber(user.points.winnings) }}
                      {{ pluralize("point", user?.points?.winnings ?? 0) }}
                    </div>

                    <div class="text-lg mb-0 text-gray-400">Current Balance</div>
                    <div class="text-base mb-4">
                      {{ formatNumber(user?.points?.total ?? 0) }}
                      {{ pluralize("point", user?.points?.total ?? 0) }}
                    </div>

                    <div
                      @click="setShowDetail('trivia', !showDetails.trivia)"
                      class="mb-6 underline text-ch1blue-light cursor-pointer"
                    >
                      {{ showDetails.trivia ? "Hide" : "Show" }} detail
                    </div>

                    <template v-if="showDetails.trivia">
                      <div class="text-lg mb-0 text-gray-400">Adjustments</div>
                      <div class="text-base mb-4">
                        {{ formatNumber(user?.points?.adjustments ?? 0) }}
                        {{ pluralize("point", user?.points?.adjustments ?? 0) }}
                      </div>

                      <div class="text-lg mb-0 text-gray-400">Redemptions</div>
                      <div class="text-base mb-4">
                        {{ formatNumber(user?.points?.redemptions ?? 0) }}
                        {{ pluralize("point", user?.points?.redemptions ?? 0) }}
                      </div>
                    </template>
                    <template v-if="user?.points?.total > 2">
                      <div>
                        <button @click="redeemPoints" class="btn" :disabled="loading">
                          Convert points to credits
                        </button>
                      </div>
                      <div class="mt-2 text-sm text-gray-400">2 points = 1 credit</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-8 rounded-xl bg-white box-shadow-std mb-8">
              <div class="mb-4 pb-4 border-b border-gray-500">
                <h1 class="font-maindisplay font-bold text-lg">Memory</h1>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <router-link :to="{ name: 'Memory' }">
                    <div
                      class="h-48 bg-cover flex items-center justify-center bg-bottom rounded-lg"
                      :style="{
                        'background-image': `url('${require('@/assets/backgrounds/yacht-v1.jpg')}')`,
                      }"
                    >
                      <div class="btn btn-ch1lightblue"><strong>Play Memory Match</strong></div>
                    </div>
                    <div></div>
                  </router-link>
                </div>
                <div>
                  <div class="flex flex-col" v-if="user?.medallions">
                    <div class="text-lg mb-0 text-gray-400">Total Winnings</div>
                    <div class="text-base mb-4">
                      {{ formatNumber(user.medallions.winnings) }}
                      {{ pluralize("medallion", user?.medallions?.winnings ?? 0) }}
                    </div>

                    <div class="text-lg mb-0 text-gray-400">Current Balance</div>
                    <div class="text-base mb-4">
                      {{ formatNumber(user?.medallions?.total ?? 0) }}
                      {{ pluralize("medallion", user?.medallions?.total ?? 0) }}
                    </div>

                    <div
                      @click="setShowDetail('trivia', !showDetails.trivia)"
                      class="mb-6 underline text-ch1blue-light cursor-pointer"
                    >
                      {{ showDetails.trivia ? "Hide" : "Show" }} detail
                    </div>

                    <template v-if="showDetails.trivia">
                      <div class="text-lg mb-0 text-gray-400">Adjustments</div>
                      <div class="text-base mb-4">
                        {{ formatNumber(user?.medallions?.adjustments ?? 0) }}
                        {{ pluralize("medallion", user?.medallions?.adjustments ?? 0) }}
                      </div>

                      <div class="text-lg mb-0 text-gray-400">Redemptions</div>
                      <div class="text-base mb-4">
                        {{ formatNumber(user?.medallions?.redemptions ?? 0) }}
                        {{ pluralize("medallion", user?.medallions?.redemptions ?? 0) }}
                      </div>
                    </template>
                    <template v-if="user?.medallions?.total > 0">
                      <div>
                        <button @click="redeemMedallions" class="btn" :disabled="loading">
                          Convert medallions to credits
                        </button>
                      </div>
                      <div class="mt-2 text-sm text-gray-400">1 medallion = 25 credits</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-8 rounded-xl bg-white box-shadow-std mb-8">
              <div class="mb-4 pb-4 border-b border-gray-500">
                <h1 class="font-maindisplay font-bold text-lg">Poker</h1>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <router-link :to="{ name: 'Poker' }">
                    <div
                      class="h-48 bg-cover flex items-center justify-center bg-center rounded-lg"
                      :style="{
                        'background-image': `url('${require('@/assets/backgrounds/poker-v2.jpg')}')`,
                      }"
                    >
                      <div class="btn btn-ch1lightblue"><strong>Play Powerup Poker</strong></div>
                    </div>
                    <div></div>
                  </router-link>
                  <div class="text-center mt-4">
                    <router-link
                      :to="{ name: 'PokerStandard' }"
                      class="link underline text-ch1blue-light font-blue"
                    >
                      Or play standard draw poker
                    </router-link>
                  </div>
                </div>
                <div>
                  <div class="flex flex-col" v-if="user?.chips">
                    <div class="text-lg mb-0 text-gray-400">Total Winnings</div>
                    <div class="text-base mb-4">
                      {{
                        formatNumber(
                          (user?.chips?.winnings ?? 0) + (user?.chips?.winnings_freeplay ?? 0)
                        )
                      }}
                      {{
                        pluralize(
                          "chip",
                          (user?.chips?.winnings ?? 0) + (user?.chips?.winnings_freeplay ?? 0)
                        )
                      }}
                    </div>

                    <div class="text-lg mb-0 text-gray-400">Current Balance</div>
                    <div class="text-base mb-4">
                      {{ formatNumber(user?.chips?.total ?? 0) }}
                      {{ pluralize("chip", user?.chips?.total ?? 0) }}
                    </div>

                    <div
                      @click="setShowDetail('poker', !showDetails.poker)"
                      class="mb-6 underline text-ch1blue-light cursor-pointer"
                    >
                      {{ showDetails.poker ? "Hide" : "Show" }} detail
                    </div>

                    <template v-if="showDetails.poker">
                      <div class="text-lg mb-0 text-gray-400">Total Powerup Winnings</div>
                      <div class="text-base mb-4">
                        {{ formatNumber(user.chips?.winnings ?? 0) }}
                        {{ pluralize("chip", user?.chips?.winnings ?? 0) }}
                      </div>

                      <div class="text-lg mb-0 text-gray-400">Total Standard Winnings</div>
                      <div class="text-base mb-4">
                        {{ formatNumber(user.chips?.winnings_freeplay ?? 0) }}
                        {{ pluralize("chip", user?.chips?.winnings_freeplay ?? 0) }}
                      </div>

                      <div class="text-lg mb-0 text-gray-400">Adjustments</div>
                      <div class="text-base mb-4">
                        {{ formatNumber(user?.chips?.adjustments ?? 0) }}
                        {{ pluralize("chip", user?.chips?.adjustments ?? 0) }}
                      </div>
                      <div class="text-lg mb-0 text-gray-400">Redemptions</div>
                      <div class="text-base mb-4">
                        {{ formatNumber(user?.chips?.redemptions ?? 0) }}
                        {{ pluralize("chip", user?.chips?.redemptions ?? 0) }}
                      </div>

                      <template v-if="user.chips.best_poker_day">
                        <div class="text-lg mb-0 text-gray-400">Best Powerup Day</div>
                        <div class="text-base mb-4">
                          {{ formatDate(user.chips.best_poker_day.date) }}:
                          {{ formatNumber(user.chips?.best_poker_day?.winnings_final ?? 0) }}
                          {{ pluralize("chip", user?.chips?.best_poker_day?.winnings_final ?? 0) }}
                        </div>
                      </template>
                    </template>
                    <template v-if="user?.chips?.total > 10">
                      <div>
                        <button @click="redeemChips" class="btn" :disabled="loading">
                          Convert chips to credits
                        </button>
                      </div>
                      <div class="mt-2 text-sm text-gray-400">10 chips = 1 credit</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="p-8 rounded-xl bg-white box-shadow-std mb-8">
              <div class="mb-4 pb-4 border-b border-gray-500">
                <h1 class="font-maindisplay font-bold text-lg">Portal Expeditions</h1>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <router-link :to="{ name: 'PortalRedemption' }">
                    <div
                      class="h-48 bg-cover flex items-center justify-center bg-center rounded-lg"
                      :style="{
                        'background-image': `url('${require('@/assets/backgrounds/portal-v1.jpg')}')`,
                      }"
                    >
                      <div class="btn btn-ch1lightblue">
                        <strong>Go to Portal Expeditions</strong>
                      </div>
                    </div>
                    <div></div>
                  </router-link>
                </div>
                <div>
                  <div class="flex flex-col mb-8" v-if="user?.chips">
                    <div class="text-lg mb-0 text-gray-400">About</div>
                    <div class="text-base mb-4">
                      You can fund as many expeditions as you can afford, with each expedition
                      bringing back a single Citizen, Competitor, or Steward that has been lost.
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="p-8 rounded-xl bg-white box-shadow-std mb-8">
              <div class="mb-4 pb-4 border-b border-gray-500">
                <h1 class="font-maindisplay font-bold text-lg">Your Credits</h1>
              </div>
              <div class="">
                <div class="text-lg mb-0 text-gray-400">Earnings</div>
                <div class="text-base mb-4">
                  {{ formatNumber(user?.credits?.adjustments ?? 0) }}
                  {{ pluralize("credit", user?.credits?.adjustments ?? 0) }}
                </div>
                <!-- <div class="text-lg  mb-0 text-gray-400">Daily Bonuses</div> -->
                <!-- <div class="text-base  mb-4">{{ user.chips.bonuses }} chips</div> -->
                <div class="text-lg mb-0 text-gray-400">Redemptions</div>
                <div class="text-base mb-4">
                  {{ formatNumber(user?.credits?.redemptions ?? 0) }}
                  {{ pluralize("credit", user?.credits?.redemptions ?? 0) }}
                </div>
                <div class="text-lg mb-0 text-gray-400">Current Balance</div>
                <div class="text-base">
                  {{ formatNumber(user?.credits?.total ?? 0) }}
                  {{ pluralize("credit", user?.credits?.total ?? 0) }}
                </div>
              </div>
            </div>

            <div class="p-8 rounded-xl bg-white box-shadow-std">
              <div class="mb-4 pb-4 border-b border-gray-500">
                <h1 class="font-maindisplay font-bold text-lg">Earnings and Redemptions</h1>
              </div>
              <div>
                <div
                  @click="() => (showRedemptionsModal = true)"
                  class="underline text-ch1blue-light cursor-pointer"
                >
                  Click here to see redemptions history
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LoadUser>
  <Fixed v-if="showRedemptionsModal">
    <Overlay @click="() => (showRedemptionsModal = false)">
      <Modal>
        <h3 class="font-maindisplay font-bold text-lg mb-4">Earnings and Redemptions</h3>

        <div class="flex flex-col">
          <div
            v-for="(line, l) in user?.point_adjustments"
            :key="l"
            class="mb-2 pb-2 border-b border-gray-200"
          >
            <div class="flex items-center justify-between">
              <div>
                <span v-if="line.quantity">&#43;</span>{{ formatNumber(line.quantity) }}
                {{ pluralize(line.denomination, line.quantity) }}
              </div>
              <div class="text-red-400">
                <span v-if="line.from_quantity">&#45;</span>{{ formatNumber(line.from_quantity) }}
                {{ pluralize(line.from_denomination, line.from_quantity) }}
              </div>
            </div>
            <div v-if="line.note" class="italic text-gray-400 text-xs">
              {{ line.note }} on {{ formatDate(line.created_at) }}
            </div>
          </div>
        </div>
      </Modal>
    </Overlay>
  </Fixed>
</template>

<script setup>
import router from "@/router";
import { onMounted, defineProps, ref } from "vue";
import Navigation from "@/components/Home/Navigation.vue";
import Roadmap from "@/components/Home/Roadmap.vue";
import Footer from "../components/Home/Footer.vue";
import NavigationNew from "@/components/NavigationNew.vue";

const props = defineProps({
  preview: String,
});

const willredirect = ref(false);

// onMounted(() => {
//   if (props.preview !== "yes") {
//     router.push({ name: "Poker" });
//   } else {
//     willredirect.value = false;
//   }
// });
</script>

<template>
  <div class="home relative">
    <div
      class="h-screen w-full flex flex-col"
      :style="{
        // 'background-image': `url('${require('@/assets/v2/paper-medium_blue-sm-min.jpg')}')`,
      }"
    >
      <NavigationNew mode="dark" />

      <div class="">
        <div class="bg-white pt-32 pb-24">
          <div class="container">
            <div class="flex flex-col xl:flex-row items-center">
              <div class="w-full lg:w-3/5 flex-shrink-0 mb-12 xl:mb-0">
                <div
                  class="font-brother text-3xl md:text-4xl lg:text-5xl xl:text-9xl font-black xl:leading-085 text-blue text-center xl:text-left"
                >
                  MOBILE GAMES FOR EVERYONE
                </div>
              </div>
              <div class="text-ch1blue px-12 lg:px-20 text-xl text-center xl:text-right">
                The Society of the Hourglass features games that are <br />appropriate for all ages!
                <br /><br />With one game published and more on the way, we are excited to bring you
                fun and engaging experiences that you can play on your phone or tablet with the
                whole family.
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="py-24">
            <h2 class="font-bold mb-8 text-4xl font-brother">Cointo</h2>

            <div class="mb-10 flex flex-col md:flex-row items-center">
              <div class="mb-8 md:mb-0">
                <img
                  :src="require('@/assets/mobile-games/cointo-screenshot-01.png')"
                  alt=""
                  class=""
                />
              </div>
              <div class="pl-0 md:pl-12">
                <p>
                  Cointo is a game of strategy for 2 players that can be learned in just a few
                  minutes.
                </p>
                <p>
                  Like tic-tac-toe with a twist, Cointo will have you thinking several steps ahead
                  of your opponent to try to win!
                </p>
                <p>
                  With playful, hand-drawn art and simple mechanics, Cointo is suitable for all ages
                </p>
              </div>
            </div>

            <div class="mb-10 flex flex-col md:flex-row items-center">
              <div class="pr-0 md:pr-12 w-full md:w-2/3">
                <h3 class="font-bold text-xl mb-6">Cointo Game Play</h3>
                <p>
                  You will be placing coins on a 4 x 4 grid to try to create a sequence of 4 coins
                  with at least one common attribute - in other words, get 4 in a row! The catch is
                  that your opponent will choose which coin you place.
                </p>
                <p>
                  All game pieces are shared - whoever places the final piece to create a sequence
                  of 4 is the winner.
                </p>
                <p>
                  Players take turns placing the coin selected by their opponent, and then choosing
                  a coin for their opponent to place, until someone has won or the game ends in a
                  draw.
                </p>
                <p>
                  Each coin has a unique combination of the following attributes:<br />
                  • Silver or gold<br />
                  • Square or circle<br />
                  • Big or small<br />
                  • Blank or logo
                </p>
              </div>

              <div>
                <img :src="require('@/assets/mobile-games/cointo-screenshot-02.png')" alt="" />
              </div>
            </div>

            <div class="mb-10 flex flex-col md:flex-row items-center">
              <div class="pr-0 md:pr-12 w-full md:w-2/3">
                <h3 class="font-bold text-xl mb-6">Features</h3>
                <p>
                  • Local play for 2 players<br />
                  • Each game takes 5-15 minutes<br />
                  • Hand-drawn illustrations from The Society of the Hourglass<br />
                  • Suitable for all ages
                </p>
              </div>
              <div>
                <img :src="require('@/assets/mobile-games/cointo-screenshot-03.png')" alt="" />
              </div>
            </div>

            <div class="flex items-center justify-center flex-col py-8">
              <div class="font-bold font-brother text-xl mb-6">Available now:</div>
              <div class="flex items-center justify-between w-full max-w-xl mx-auto">
                <a
                  href="https://infinitygametable.com/game/cointo/"
                  target="_blank"
                  rel="noref noopener"
                  class="text-center font-bold"
                >
                  <div class="mb-4">
                    <img :src="require('@/assets/mobile-games/igt-logo.svg')" class="h-24" alt="" />
                  </div>
                  <div>Infinity Game Table</div>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.societyofthehourglass.cointo"
                  target="_blank"
                  rel="noref noopener"
                  class="text-center font-bold"
                >
                  <div class="mb-4">
                    <img
                      :src="require('@/assets/mobile-games/google-play-badge.svg')"
                      class="h-24"
                      alt=""
                    />
                  </div>
                  <div>Google Play</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.top-banner {
  @apply bg-green-500 w-full mb-6 py-2 text-center px-4;
}
.hero {
  @apply flex flex-col bg-cover bg-center items-center w-full;
}
.hero-inner {
  @apply h-screen flex flex-col bg-cover bg-center items-center w-full;
}
.hero-content {
  @apply flex-grow flex items-center justify-center flex-col;
}
.hero-text {
  @apply font-maindisplay font-black text-white text-xl md:text-3xl text-center mx-4;
  text-shadow: -4px 4px 0px rgba(0, 0, 0, 0.15);
}
.hero-bottom-cta {
  @apply flex justify-center items-center;
}
.hero-bottom-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  z-index: 200;
  @apply top-6 text-white px-6 py-4 rounded-lg bg-ch1blue-dark font-maindisplay text-xl relative;
}
.story {
  z-index: 100;
  @apply relative pb-6 md:pb-12 lg:pb-0 bg-repeat;
}
.story-nav {
  @apply pt-12 pb-4 lg:pb-12;
}
.story-bg {
  @apply w-full relative opacity-0 lg:opacity-100 mx-auto bg-contain md:bg-fixed hidden lg:block;
  max-width: 1400px;
}
.story-inner {
  @apply lg:absolute top-0 left-0 right-0 bottom-0 mx-auto;
  max-width: 1400px;
}
.story-text {
  @apply w-full md:w-2/3 lg:w-1/2 pt-0 p-8;
}
.story-title {
  @apply font-maindisplay text-2xl lg:text-6xl text-blue font-black mt-0 mb-6 lg:mt-4 lg:mb-12;
}
.story-body {
  @apply text-base md:text-lg lg:text-xl xl:text-2xl mb-8;
}
.story-body p {
  @apply mb-4 md:mb-10;
}
.story-bottom-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  @apply text-white px-6 py-4 rounded-lg bg-ch1red font-maindisplay text-xl relative inline-block;
}

.signup-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  @apply text-white px-12 py-4 rounded-lg bg-ch1blue font-maindisplay text-xl relative;
}

.cta-title {
  text-shadow: -4px 4px 0px rgba(0, 0, 0, 0.15);
  @apply font-maindisplay text-white text-2xl mb-6;
}
.cta-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  @apply text-white px-12 py-4 rounded-lg bg-ch1blue font-maindisplay text-xl relative;
}
</style>
